<script setup lang="ts">
import { ref, watch } from 'vue';

import LocationHandler from '@/components/shared/LocationHandler.vue';

import { useCustomizerStore } from '@/stores/customizer';
import { useEcomStore } from '@/stores/apps/eCommerce';
import { useInvoiceStore } from '@/stores/apps/invoice';
import { useAuthStore } from '@/stores/auth';

import { IconBarcode, IconGridDots, IconQrcode, IconShoppingCart, IconCreditCardPay, IconPlus, IconTrash, IconMinus, IconHome, IconArrowDown, IconChevronsDown, IconChevronsUp, IconShoppingBag } from '@tabler/icons-vue';
import SnackBar from '@/components/ui-components/alert/SnackBar.vue';
import scanErrorImage from '/public/images/lottie_files/scan-error.json';
// import scanButtonImage from '/public/images/lottie_files/scan-button.json';
import { useI18n } from 'vue-i18n'
const { t } = useI18n()
const route = useRoute()

const stores = useStoresStore()
const ecomStore = useEcomStore();
const invoice = useInvoiceStore();
const auth = useAuthStore();

// Icon Imports
const customizer = useCustomizerStore();
const showSearch = ref(false);
const appsDrawer = ref(false);
const loadingIndicator = useLoadingIndicator();
import MyBarcodeReader from '@/components/shared/MyBarcodeReader.vue';
const showCamera = ref(false)
const barcodeSearch = ref("")
const notFoundProduct = ref(false);
const notFoundProductCode = ref();
const showPackagingModal = ref(false);
const packagingItem = ref();
const animateScanButton = ref(false);
const alertMaximumItems = ref(false);
const gpsTooltipVisible = ref(false);
const profileTooltipVisible = ref(false);
const scanTooltipVisible = ref(false);
const payTooltipVisible = ref(false);
const showOverlay= ref(false);
const isPackingAdded = computed(() => {
    return stores.getPackagingOption.added
});

const snackAlertMessage = ref({
    message: '',
    type: '',
    show: false,
    timeout: 1000
})

const getCart = computed(() => {
    return ecomStore.getCart;
});

const getSelectedStore = computed(() => {
    return stores.getSelectedStore;
});

const blockActions = computed(() => {
    return Object.keys(stores.getSelectedStore).length === 0 || useInvoiceStore().paymentStatus !== ''
});

function handleHelpers(show="", from=""){
    console.log("show", show, "from", from)
    showOverlay.value = show !== "false" ? true : false; 
    gpsTooltipVisible.value = false;
    profileTooltipVisible.value = false;
    scanTooltipVisible.value = false; 
    payTooltipVisible.value = false; 

    if (from === "overlay" && auth.showTutorial === 'gps') {
        show = "none";
    }

    switch (show) {
        case 'gps': gpsTooltipVisible.value = true; break;
        case 'profile': profileTooltipVisible.value = true; break;
        case 'scan': scanTooltipVisible.value = true;  break;
        case 'pay': payTooltipVisible.value = true;  break;
        case 'none': showOverlay.value = false;  break;
    }
}

const getTotalQty = computed(() => {
    return useEcomStore().getTotalQty || 0;
});

const maxQtyIsSoon = computed(() => {
    return getTotalQty.value >= stores.maximumItems-1
});

const validateMaxItems = computed(() => {
    const maxItems = stores.maximumItems;
    const actualItems = getTotalQty.value || 0;
    return actualItems >= maxItems;
});

watch(() => validateMaxItems.value, () => {
    if (validateMaxItems.value) {
        alertMaximumItems.value = true;
    }
})


watch([() => auth.showTutorial], async ([value]) => {
    handleHelpers(value, "watch");
})

const decrementQty = async (pk:number) => {
    await ecomStore.decrementQty(pk)
    snackAlertMessage.value = { show: true, type: '', message: t('cart.ItemQuantityReducedSuccessfully'), timeout: 10000}
}
const incrementQty = async (pk:number) => {
    ecomStore.incrementQty(pk)
    snackAlertMessage.value = { show: true, type: '', message: t('cart.ItemQuantityIncrementedSuccessfully'), timeout: 10000}
}
const deleteProduct = async (pk:number) => {
    await ecomStore.deleteProduct(pk)
    snackAlertMessage.value = { show: true, type: 'success', message: t('cart.ItemDeletedSuccessfully'), timeout: 10000}
}

const toggleBarcodeReader = async (show:boolean) => {
    showCamera.value = show;
    if (!show){
        barcodeSearch.value = ''
    } 
}

const handleBarcodeReaded = async (code:string) => {
    if (code && code !== ""){
        loadingIndicator.start()
        try {
            await ecomStore.fetchProduct(code);
        } catch (e:any) {
            notFoundProductCode.value = code;
            notFoundProduct.value = true;
            console.error(`Error fetching product: ${e.toString()}`);
            toggleBarcodeReader(false)
            return;
        }
        snackAlertMessage.value = { show: true, type: 'success', message: t('cart.ItemAddedSuccessfully'), timeout: 3000}
        toggleBarcodeReader(false)
        loadingIndicator.finish()
    } else {
        toggleBarcodeReader(false)
        loadingIndicator.finish()
    }
}

const manualBarcodeReader = async (code:string) => {
    loadingIndicator.start()
    toggleBarcodeReader(false);
    if (code && code.split(' ').length > 1){
        const codes = code.split(' ')
        if (stores.maximumItems - getTotalQty.value - codes.length < 0){
            snackAlertMessage.value = { show: true, type: 'error', message: t('cart.MaxItemsError'), timeout: 3000}
            barcodeSearch.value = codes.splice(0, stores.maximumItems - getTotalQty.value).join(' ');
            return;
        };
        
        notFoundProductCode.value = null;
        notFoundProduct.value = false;
        for (const code of codes){
            try {
                await ecomStore.fetchProduct(code);
                
            } catch (e:any) {
                notFoundProductCode.value = !notFoundProduct.value ? code : notFoundProductCode.value + ", " + code;
                notFoundProduct.value = true;
                console.error(`Error fetching product: ${e.toString()}`);
                continue;
            }
            snackAlertMessage.value = { show: true, type: 'success', message: t('cart.ItemAddedSuccessfully')}
        }
    } else {
        try {
            await ecomStore.fetchProduct(code);
            notFoundProductCode.value = null;
            notFoundProduct.value = false;
        } catch (e:any) {
            notFoundProductCode.value = code;
            notFoundProduct.value = true;
            console.error(`Error fetching product: ${e.toString()}`);
            loadingIndicator.finish()
            return;
        }
        snackAlertMessage.value = { show: true, type: 'success', message: t('cart.ItemAddedSuccessfully')}
    }
    loadingIndicator.finish()
}

const validateCartPacking = async () => {
    await ecomStore.validateProducts();
    try {
        if (isPackingAdded.value === true){
            packagingItem.value = <Products>await ecomStore.getCart.find(async (product:any) => product.barcode === stores.getPackagingOption.ean)
        } else if (stores.getPackagingOption && stores.getPackagingOption.ean) {
            packagingItem.value = <Products>await ecomStore.fetchProduct(stores.getPackagingOption.ean, true)
        }
    } catch (e) {
        // packagingItem = false (computed)
    }
} 

const sleep = (ms: number) => new Promise(resolve => setTimeout(resolve, ms));

const goToCheckout = async () => {
    alertMaximumItems.value = false; 
    animateScanButton.value = false; 
    await validateCartPacking(); 
    if (stores.getPackagingOption.added === false){
        showPackagingModal.value = true
    } else {
        alertMaximumItems.value = false; 
        navigateTo('/apps/ecommerce/checkout'); 
    } 
}

onMounted(async () => {
    // animateScanButton.value = false;
    validateCartPacking();
    // await sleep(1000); 
    // await sleep(300); 
    // animateScanButton.value = true;
    if (validateMaxItems.value && invoice.paymentStatus !== 'approved') {
        alertMaximumItems.value = true;
    }
    if (getCart.value.length === 0){
        if (auth.showTutorial === 'true') {
            handleHelpers("overlay", "onMounted");
        }
    } else {
        auth.setToShowTutorial("false")
        handleHelpers("none", "onMounted");
    }
});

</script>

<template>
    <v-progress-linear v-if="route.path === '/' && stores.maximumItems <= 50 && getTotalQty > 0" 
        class="progress-bar-products" :color="stores.maximumItems === getTotalQty ? 'error' : maxQtyIsSoon ? 'primary' : 'success'" :model-value="(getTotalQty / stores.maximumItems) * 100" 
        :height="maxQtyIsSoon ? 16 : 6"  >
        <template v-slot:default="{ value }">
            <span v-if="stores.maximumItems === getTotalQty" class="text-white pr-1 text-xs">{{ $t('maximum_reached') }}:</span>
            <span v-if="maxQtyIsSoon" class="text-white text-xs">{{ getTotalQty }} {{ $t('basic.of') }} {{ stores.maximumItems }}</span>
        </template>
    </v-progress-linear>
    <v-overlay 
        v-model="showOverlay" 
        persistent
        @click="gpsTooltipVisible ? auth.isThirdpartyFilled ? handleHelpers('scan', 'overlay') : handleHelpers('profile','overlay') :
                profileTooltipVisible ? handleHelpers('scan', 'overlay') :
                scanTooltipVisible ? handleHelpers('pay', 'overlay') : 
                payTooltipVisible ? handleHelpers('none', 'overlay') : 
                                    handleHelpers('none', 'overlay')" 
        theme="dark">
    </v-overlay>
    <v-app-bar elevation="10" >
        <div class="maxWidth v-toolbar__content">
            <v-btn class="hidden-lg-and-up ml-3" icon rounded="sm" variant="flat" size="small" @click="navigateTo('/')">
                <IconHome size="38" stroke-width="1.4" />
            </v-btn>
            <v-spacer /> 
            <v-locale-provider>
                <div class="hidden-sm-and-down">
                    <LayoutFullLogo />
                </div>
                <div class="hidden-sm-and-up pa-1">
                    <LayoutFullLogoMini />
                </div>
            </v-locale-provider> 
            <v-spacer /> 
            <LayoutFullHorizontalHeaderProfileDD >
                <v-tooltip 
                    v-model="profileTooltipVisible"
                    :open-delay="200"
                    location="top" 
                    content-class="elevation-3 rounded-lg opacity-10"
                    :offset="40"
                    :min-width="200"
                    :max-width="230"
                    manual
                    @click:outside="handleHelpers('scan','profile')"
                    >
                    <div class="text-h6 text-center px-2 py-2" >
                        <div class="text-right w-100">
                            <IconChevronsUp size="28" style="margin-top: -5px"  />
                        </div>
                        <div class="text-h3">{{ $t('basic.remember') }}</div>
                        <p class="font-weight-light py-2">
                        {{$t('fill_information_to_payment_process')}}
                        </p>
                    </div>
                    <template v-slot:activator="{ props }">
                        <button v-bind="props" disabled style="width:2px; border: 0; display: inline-block; height:2px; margin: 0px; padding: 0px" ></button>
                    </template>
                </v-tooltip>
            </LayoutFullHorizontalHeaderProfileDD>
        </div>
    </v-app-bar>
    <v-bottom-navigation v-if="route.path === '/'" location="bottom" elevation-0 flat style="overflow: visible" >

        <LocationHandler >
            <v-tooltip 
                v-model="gpsTooltipVisible"
                :open-delay="200"
                location="top" 
                content-class="elevation-3 rounded-lg opacity-10"
                :offset="60"
                :min-width="200"
                :max-width="230"
                manual
                @click:outside="handleHelpers(auth.isThirdpartyFilled ? 'none': 'profile', 'gps')"
                >
                <div class="text-h6 text-center px-2 py-2" >
                    <div class="text-h3">{{ $t('basic.step') }} 1</div>
                    <p class="font-weight-light py-2">
                    {{$t('enable_gps_and_continue')}}
                </p>
                    <IconChevronsDown size="28" style="margin-bottom: -10px" />
                </div>
                <template v-slot:activator="{ props }">
                    <button v-bind="props" disabled style="width:2px; border: 0; display: inline-block; height:2px; margin: 0px; padding: 0px" ></button>
                </template>
            </v-tooltip>
        </LocationHandler>

        <v-btn
            variant="flat"
            @click="toggleBarcodeReader(true)"
            :disabled="blockActions || validateMaxItems"
            class="scan-button"
            icon
        >
            <IconBarcode stroke-width="1.6" size="48" style="margin-bottom: -5px" />
            <span class="text-xs font-weight-bold">{{ $t('index.scan_qr_code') }}</span>

            <v-tooltip 
                v-model="scanTooltipVisible"
                :open-delay="200"
                location="top" 
                content-class="elevation-3 rounded-lg opacity-10"
                :offset="60"
                :min-width="200"
                :max-width="230"
                manual
                @click:outside="handleHelpers('pay','scan')"
                >
                <div class="text-h6 text-center px-2 py-2" >
                    <div class="text-h3">{{ $t('basic.step') }} 1</div>
                    <p class="font-weight-light py-2">
                    {{$t('take_products_and_scan')}}
                </p>
                    <IconChevronsDown size="28" style="margin-bottom: -10px" />
                </div>
                <template v-slot:activator="{ props }">
                    <button v-bind="props" disabled style="width:2px; border: 0; display: inline-block; height:2px; margin: 0px; padding: 0px" ></button>
                </template>
            </v-tooltip>
        </v-btn>

        <v-tooltip 
            v-model="payTooltipVisible"
            :open-delay="200"
            location="top" 
            content-class="elevation-3 rounded-lg opacity-10"
            :offset="10"
            :min-width="200"
            :max-width="230"
            manual
            @click:activator="() => {}"
            @click:outside="handleHelpers('none','pay')"
            >
            <div class="text-h6 text-center px-2 py-2">
                    <div class="text-h3">{{ $t('basic.step') }} 2</div>
                    <p class="font-weight-light py-2">
                        {{$t('whan_you_ready_go_to_pay')}}
                    </p>
                    <IconChevronsDown size="28" style="margin-bottom: -10px" />
                </div>
            <template v-slot:activator="{ props }">
                <v-btn 
                    :disabled="useEcomStore().getTotalQty === 0" 
                    variant="text" 
                    @click="goToCheckout" 
                    v-bind="props">
                    <v-badge color="primary" :content="useEcomStore().getTotalQty" bordered>
                        <IconShoppingBag stroke-width="1.5" size="28"  />
                    </v-badge>
                    <span class="text-xs">{{ useInvoiceStore().paymentStatus == 'approved' ? $t('basic.payed') : $t('basic.toPay') }}</span>
                </v-btn>
            </template>
        </v-tooltip>

    </v-bottom-navigation>
    <!-- 
    <v-navigation-drawer v-model="appsDrawer" location="right" temporary>
        <LayoutFullVerticalHeaderRightMobileSidebar />
    </v-navigation-drawer> 
    -->
    <v-dialog v-model="showPackagingModal" width="auto" min-width="320">
      <v-card
        class="mx-auto"
      >
        <h3 class="text-h3 text-primary text-center pa-4">{{ $t('shopping.gotAPackage')}}</h3>

        <VImg src="/images/products/snapei-bag.png" height="300px" cover />
        <div class="py-4 text-center" v-if="packagingItem">

            <span>
                {{ packagingItem.name }}<br>
                <small class='text-xs text-grey-lighten'>{{ packagingItem.barcode }}</small>
            </span>
            <h2>{{ formatCurrency(packagingItem.price) }}</h2>
            
            <v-btn
                v-if="!stores.getPackagingOption.added"
                class="text-none"
                color="primary"
                min-width="92"
                rounded
                @click="async () => packagingItem = await ecomStore.addToCart(packagingItem)"
            >
                {{ $t('basic.add')}}
            </v-btn>
            <v-sheet v-else class="pt-2">
                <v-btn v-if="packagingItem.qty >= 2" size="x-small" variant="plain" @click="async () => { decrementQty(packagingItem.pk); packagingItem.qty -= 1; }" >
                    <IconMinus size="18" />
                </v-btn>
                <v-btn v-if="(packagingItem.qty === 1)" size="x-small" variant="plain" @click="async () => { deleteProduct(packagingItem.pk); validateCartPacking(); }" >
                    <IconTrash size="18" class="text-primary" />
                </v-btn>
                <v-btn size="x-small" class="text-subtitle-2 text-right" variant="plain">
                    {{ packagingItem.qty }}
                </v-btn>
                <v-btn size="x-small" @click="async () => { incrementQty(packagingItem.pk); packagingItem.qty += 1; }" variant="plain">
                    <IconPlus size="18" />
                </v-btn>
            </v-sheet>
        </div>

        <v-divider></v-divider>

        <div class="pa-4 d-flex justify-space-between">
            <v-btn
                variant="tonal"
                rounded
                @click="showPackagingModal = false; animateScanButton = true;"
            >
                {{ $t('basic.cancel')}}
            </v-btn>
            <v-btn
                :variant="!stores.getPackagingOption.added ? 'outlined' : 'flat'"
                color="primary"
                rounded
                @click="() => { showPackagingModal = false; navigateTo('/apps/ecommerce/checkout') }"
            >
                <IconCreditCardPay size="20" class="mr-1" /> {{ (!stores.getPackagingOption.added ? "No, " : "") + $t('Checkout') }}
            </v-btn>
        </div>
      </v-card>
    </v-dialog>
    <v-dialog 
        v-model="alertMaximumItems" 
        title="Máximo de productos permitidos"
        max-width="460" 
        color="primary"
        >
        <v-card>
            <template v-slot:title>
                <h4 class="text-h4 text-primary">{{ $t('cart.areYouReadyToPay')}}</h4>
            </template>
            <template v-slot:append>
            <v-btn icon="$close" variant="text" @click="alertMaximumItems = false"></v-btn>
            </template>
            <v-card-text class="text-center">
                {{$t('cart.MaxItemsError')}}: <b>{{ stores.maximumItems }}</b><br/>
                <p class="text-primary pt-4">{{ $t('slogan') }}</p>
            </v-card-text>
            <v-card-actions>
                <v-btn @click="goToCheckout()" block size="large" color="primary" variant="flat" >
                    <IconCreditCardPay stroke-width="1.5" size="22" class="mr-2" /> {{ $t('Go to Pay')}}
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="showCamera" width="100vw" :max-width="500" class="barcode-reader-dialog h-screen">
        <MyBarcodeReader @onBarcodeRead="handleBarcodeReaded" >
            
        </MyBarcodeReader>
    </v-dialog>
    <v-dialog v-model="notFoundProduct" max-width="400" width="auto">
      <v-card>
        <h3 class="text-h3 text-primary text-center pa-4">{{ $t('product not found') }}</h3>

        <div class="py-12 text-center">
            <Vue3Lottie :animationData="scanErrorImage" />
            <p class="px-6 text-grey600 text-xs">{{ $t('barcode detected') }}: {{ notFoundProductCode }}</p>
        </div>

        <v-divider></v-divider>

        <div class="pa-4 d-flex justify-space-between">
        <v-btn
            class="text-none"
            color="medium-emphasis"
            min-width="92"
            variant="outlined"
            rounded
            @click="notFoundProduct = null;"
          >
            {{ $t('basic.close')}}
          </v-btn>
          <v-btn
            class="text-none"
            color="primary"
            min-width="92"
            variant="outlined"
            rounded
            @click="notFoundProduct = null; notFoundProductCode = null; toggleBarcodeReader(true)"
          >
            {{ $t('basic.retry')}}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>

    <SnackBar :alert="snackAlertMessage" :opacity="0.5" timeout="1500" />
</template>
<style lang="scss" sco >
.scan-button {
    background-color: rgb(var(--v-theme-surface));
    border-radius: 100% !important;
    padding: 2px !important;
    width: 80px !important;
    height: 80px !important;
    margin-top: -22px !important;
    box-shadow: 0px -3px 0px 1px rgba(0, 0, 0, 0.05) !important;
    transition: all 1s ease;
    transform: scale(1);
    
    &.animation {
        // z-index: 1025;
        // left: 50%; 
        // transform: translateX(-50%);
        // height: 80px; 
        // width: 80px;
        opacity: 1;
    }
}
.barcode-reader-dialog {
    .v-overlay__content {
        margin: 0px !important;
        max-height: 100vh;
        max-width: 100vw;
    }
}
.progress-bar-products {
    top: 64px !important; 
    z-index: 1000;
    position: fixed;
}
.custom-img-box {
    width: 60px;
    height: 60px;
}
button.v-btn--variant-text .v-btn__overlay,
button.v-btn--stacked .v-btn__overlay {
    background: none !important;
    opacity: 0 !important;
}
</style>