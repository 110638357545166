<script setup lang="ts">
import { ref, computed } from 'vue'
import { QrcodeStream } from 'vue-qrcode-reader'
import { useI18n } from 'vue-i18n'
import { IconLoader, IconX } from '@tabler/icons-vue';
import loadingCheckoutImage from '/public/images/lottie_files/checkout-loading.json';
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';
import cornerCamera from '@images/svgs/corner-camera.svg';

const { t } = useI18n() 

defineProps({
  unpaused: Boolean
})

/*** detection handling ***/
const result = ref()
const paused = ref(false)
const loading = ref(true)


const emit = defineEmits(['onBarcodeRead'])

async function onDetect(detectedCodes:any) {
  result.value = detectedCodes.map((code:any) => code.rawValue)
  paused.value = true;
  // if (result.value.length > 1){
  //   console.log("result > 1: ", result.value, detectedCodes)
  // } else {
  //   console.log("result = 1: ", result.value, detectedCodes.map((code:any) => code.boundingBox))
  // }
  if (result.value.length > 0){
    emit('onBarcodeRead', result.value[0])
    await timeout(300);
  }
  // paused.value = false;
}

function timeout(ms:number) {
  return new Promise((resolve) => {
    window.setTimeout(resolve, ms)
  })
}

/*** select camera ***/
const selectedConstraints = ref({ facingMode: 'environment' })
const defaultConstraintOptions = [
  { label: 'Cámara Posterior', constraints: { facingMode: 'environment' } },
  { label: 'Cámara Frontal', constraints: { facingMode: 'user' } }
]
const constraintOptions = ref(defaultConstraintOptions)

async function onCameraReady() {
  // NOTE: on iOS we can't invoke `enumerateDevices` before the user has given
  // camera access permission. `QrcodeStream` internally takes care of
  // requesting the permissions. The `camera-on` event should guarantee that this
  // has happened.
  const devices = await navigator.mediaDevices.enumerateDevices()
  const videoDevices = devices.filter(({ kind }) => kind === 'videoinput')
  paused.value = false;
  loading.value = false;

  constraintOptions.value = [
    ...defaultConstraintOptions,
    ...videoDevices.map(({ deviceId, label }) => ({
      label: `${label}`, //  (ID: ${deviceId})
      constraints: { deviceId }
    }))
  ]

  error.value = ''
}

/*** track functons ***/
function paintOutline(detectedCodes:any, ctx:any) {
  for (const detectedCode of detectedCodes) {
    const [firstPoint, ...otherPoints] = detectedCode.cornerPoints

    ctx.strokeStyle = '#FA896B'
    ctx.beginPath()
    ctx.moveTo(firstPoint.x, firstPoint.y)
    for (const { x, y } of otherPoints) {
      ctx.lineTo(x, y)
    }
    ctx.lineTo(firstPoint.x, firstPoint.y)
    ctx.closePath()
    ctx.stroke()
    return;
  }
}
function paintBoundingBox(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const {
      boundingBox: { x, y, width, height }
    } = detectedCode

    ctx.lineWidth = 3
    ctx.strokeStyle = '#FA896B'
    ctx.strokeRect(x, y, width, height)
    return; // solo para que pinte el primero detectado?
  }
}
function paintCenterText(detectedCodes, ctx) {
  for (const detectedCode of detectedCodes) {
    const { boundingBox, rawValue } = detectedCode

    const centerX = boundingBox.x + boundingBox.width / 2
    const centerY = boundingBox.y + boundingBox.height / 2

    const fontSize = Math.max(12, (50 * boundingBox.width) / ctx.canvas.width)

    ctx.font = `bold ${fontSize}px sans-serif`
    ctx.textAlign = 'center'

    ctx.lineWidth = 3
    ctx.strokeStyle = '#FA896B'
    ctx.strokeText(detectedCode.rawValue, centerX, centerY)

    ctx.fillStyle = '#5cb984'
    ctx.fillText(rawValue, centerX, centerY)
    return;
  }
}
const trackFunctionOptions = [
  { text: 'nothing (default)', value: undefined },
  { text: 'outline', value: paintOutline },
  { text: 'centered text', value: paintCenterText },
  { text: 'bounding box', value: paintBoundingBox }
]
const trackFunctionSelected = ref(trackFunctionOptions[3])

/*** barcode formats ***/

const barcodeFormats = ref({
  aztec: false,
  code_128: true,
  code_39: false,
  code_93: false,
  codabar: false,
  databar: false,
  databar_expanded: false,
  data_matrix: false,
  dx_film_edge: false,
  ean_13: true,
  ean_8: false,
  itf: false,
  maxi_code: false,
  micro_qr_code: false,
  pdf417: false,
  qr_code: true,
  rm_qr_code: false,
  upc_a: false,
  upc_e: false,
  linear_codes: false,
  matrix_codes: false
})
const selectedBarcodeFormats = computed(() => {
  return Object.keys(barcodeFormats.value).filter((format) => barcodeFormats.value[format])
})

/*** error handling ***/

const error = ref('')

function onError(err:any) {
  error.value = `[${err.name}]: `
  error.value = `ERROR: `

  if (err.name === 'NotAllowedError') {
    error.value += t('errors.cameraErrors.NotAllowedError');
  } else if (err.name === 'NotFoundError') {
    error.value += t('errors.cameraErrors.NotFoundError');
  } else if (err.name === 'NotSupportedError') {
    error.value += t('errors.cameraErrors.NotSupportedError');
  } else if (err.name === 'NotReadableError') {
    error.value += t('errors.cameraErrors.NotReadableError');
  } else if (err.name === 'OverconstrainedError') {
    error.value += t('errors.cameraErrors.OverconstrainedError');
  } else if (err.name === 'StreamApiNotSupportedError') {
    error.value += t('errors.cameraErrors.StreamApiNotSupportedError');
  } else if (err.name === 'InsecureContextError') {
    error.value += t('errors.cameraErrors.InsecureContextError');
  } else {
    error.value += err.message;
  }
}
</script>
<template>
  <div class="qr-barcode-container">
    <!-- <p v-if="constraintOptions.length > 2">
      <b>Seleccione cámara</b>:
      <v-select v-model="selectedConstraints" 
        :items="constraintOptions" 
        item-title="label" 
        item-value="constraints">
      </v-select>
    </p> -->
   
    <!--
    <p>
      <b>visor</b>:
      <select v-model="trackFunctionSelected">
        <option
          v-for="option in trackFunctionOptions"
          :key="option.text"
          :value="option"
        >
          {{ option.text }}
        </option>
      </select>
    </p> -->

    <!-- <p class="d-none">
      <b>tipos</b><br />
      <span
        v-for="option in Object.keys(barcodeFormats)"
        :key="option"
        class="barcode-format-checkbox"
      >
        <input
          type="checkbox"
          v-model="barcodeFormats[option]"
          :id="option"
        />
        <label :for="option">{{ option }}</label>
      </span>
    </p> -->

    <!-- <p class="decode-result">
      Last result: <b>{{ result }}</b>
    </p> -->

    <div class="h-screen" style="padding-bottom: 160px">
      <p class="error pa-5 text-center" v-if="error">
        {{ error }}<br />
        <v-btn @click="() => { paused = true; emit('onBarcodeRead', null) }" small icon flat >
            <IconX size="26" stroke-width="1.5" />
        </v-btn>
      </p>
      <div v-else-if="loading" class="mt-5 text-center">
        <h4 class="pa-5 text-h4">{{ paused ? 'Reactivando cámara...' : $t('basic.pleaseWait') }}</h4>
        <Vue3Lottie :animationData="loadingCheckoutImage" width="100%" class="" />
      </div>
      <div v-if="!loading" style="position: fixed; top: 28%; left: 0; right: 0; z-index: 99999">
        <div class="text-center pa-4 px-12 w-100 mt-16">
          {{ $t('scan_instructions') }}
        </div>
      </div>
      <div style="position: fixed; bottom: 0; left: 0; right: 0; z-index: 99999">
        <div class="pa-4">
          <slot>
          </slot>
        </div>
        <div v-if="!loading" class="pb-6 text-center">
          <v-btn @click="() => { paused = true; emit('onBarcodeRead', null) }" size="large" icon class="mb-1" >
              <IconX size="38" stroke-width="2.4" />
          </v-btn>
        </div>
        <!-- TODO: Instrucciones multi-idioma  -->
        <v-img src="images/banners/barcode_reader/instrucciones.png" />
      </div>
      <!-- paused: {{paused}} -->
      <qrcode-stream
        :constraints="selectedConstraints"
        :track="trackFunctionSelected.value"
        :formats="selectedBarcodeFormats"
        :paused="paused"
        @error="onError"
        @detect="onDetect"
        @camera-on="onCameraReady"
        >
        <div v-if="!loading" class="camera-overlay">
          <div class="visor">
            <div style="position: absolute; width: 40px; height: 40px; top: -2px; left: -2px; transform: rotate(270deg);">
              <v-img :src="cornerCamera" class="visor-corner" />
            </div>
            <div style="position: absolute; width: 40px; height: 40px; top: -2px; right: -2px;">
              <v-img :src="cornerCamera" class="visor-corner" />
            </div>
            <div style="position: absolute; width: 40px; height: 40px; bottom: -2px; left: -2px; transform: rotate(180deg);">
              <v-img :src="cornerCamera" class="visor-corner" />
            </div>
            <div style="position: absolute; width: 40px; height: 40px; bottom: -2px; right: -2px; transform: rotate(90deg);">
              <v-img :src="cornerCamera" class="visor-corner" />
            </div>
          </div>
        </div>
        <div v-else class="loading-indicator">
          <IconLoader size="28" stroke-width="1" color="primary" />
        </div>
      </qrcode-stream>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.qr-barcode-container {
  color: rgb(var(--v-theme-on-surface-variant));
  margin: 0px; 
  padding: 0px;
  overflow: hidden;
  background-color: rgba(var(--v-theme-surface), 0.8);
  .z-index-99 {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 99999;
  }
  .error {
    font-weight: bold;
    color: red;
  }
  .barcode-format-checkbox {
    margin-right: 10px;
    white-space: nowrap;
    display: inline-block;
  }
  .camera-overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;

    .visor {
      content: '';
      position: fixed;
      top: 20%;
      left: 50%;
      width: 90%;
      height: 30%;
      background: transparent;
      border: 1px solid rgba(var(--v-theme-surface));
      border-radius: 30px;
      transform: translate(-50%, -50%);
      box-shadow: 0 0 0 9999px rgba(var(--v-theme-surface), 0.8);
    }
  }
}
</style>